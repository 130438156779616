import { ExternalPromise } from '@sqior/js/async';
import {
  AuthConfig,
  AuthorizationHeader,
  BearerTokenAuthContext,
  TokenGenerationResult,
  UserInfoType,
} from '@sqior/js/authbase';
import { addSeconds, now } from '@sqior/js/data';
import { Logger } from '@sqior/js/log';
import Keycloak from 'keycloak-js';
import { OAuthProviderBase } from './oauth-provider-base';
import { LoginOptions, OidcClient } from './oidc-client';
import { joinUrlPath } from '@sqior/js/url';

export abstract class OAuthProvider<T extends Keycloak | OidcClient>
  extends BearerTokenAuthContext
  implements OAuthProviderBase
{
  constructor() {
    super();
    this.client = undefined;
    this.initialized = false;
    this.redirectUri = undefined;
    this.extAuthenticated = new ExternalPromise<boolean>();
    this.isAuthenticated = this.extAuthenticated.promise;
    this.refreshTime = 30;
    this.refreshCheckInterval = addSeconds(20);
    this.refreshTimer = undefined;
    this.additionalScopes = [];
  }

  init(config: AuthConfig, appUrl: string) {
    if (this.initialized) return;
    if (config.additionalScopes) this.additionalScopes = config.additionalScopes;
    const sp = new URLSearchParams(window.location.search);
    const subUser = sp.get('u');

    if (subUser) {
      const updatedSearchParams = new URLSearchParams(`u=${subUser}`);
      const url = new URL(appUrl);
      url.search = updatedSearchParams.toString();
      this.redirectUri = url.toString();
    } else {
      this.redirectUri = appUrl;
    }
    this.offlineAccess =
      config.authOfflineAccess ?? this.additionalScopes.includes('offline_access');
    /* If offline access is configured, check if it shall be skipped */
    if (this.offlineAccess && OAuthProvider.storage.getItem(SkipOfflineKey))
      this.offlineAccess = false;

    /* Set-up keycloak */
    this.client = this.createClient(config, this.redirectUri);

    /* Inject stored tokens to avoid having to log in right again after refreshing the browser */
    const user = OAuthProvider.storage.getItem('user');
    const token = user ? OAuthProvider.storage.getItem(user + '-token') : undefined;
    const refreshToken = user ? OAuthProvider.storage.getItem(user + '-refreshToken') : undefined;

    if (user)
      Logger.debug(
        [
          this.typeName,
          'client init for user:',
          user,
          'token:',
          !!token,
          'refreshToken:',
          !!refreshToken,
          'offline access:',
          this.offlineAccess,
        ],
        [
          this.typeName,
          'client init for user, ',
          'token:',
          !!token,
          'refreshToken:',
          !!refreshToken,
          'offlineAccess:',
          this.offlineAccess,
        ]
      );
    else
      Logger.debug([this.typeName, 'client init without user, offlineAccess:', this.offlineAccess]);

    /* Initialize keycloak */
    this.initializeClient(
      token ?? undefined,
      refreshToken ?? undefined,
      this.offlineAccess,
      this.additionalScopes
    )
      .then((succ) => {
        this.extAuthenticated.resolve(succ || false);
        if (succ) {
          /* If the log-in was successful, reset the skip offline data, if present */
          OAuthProvider.storage.removeItem(SkipOfflineKey);
          Logger.debug([
            this.typeName,
            `client init succeeded with${this.offlineAccess ? '' : 'out'} offline access`,
          ]);
          this.storeTokens();
        } else if (user) {
          Logger.debug([this.typeName, 'client init failed - forcing login of user:', user]);
          this.resetUserTokens(user); // for safety, so that next init call does not present invalid token again
          this.client?.login({
            redirectUri: this.redirectUri,
            loginHint: user,
            prompt: 'login',
            scope: this.offlineAccess ? 'offline_access' : undefined,
          });
        } else Logger.debug([this.typeName, ' client init failed - letting outside handle it']);
      })
      .catch((e) => {
        Logger.info([
          this.typeName,
          `client init with${this.offlineAccess ? '' : 'out'} offline access reported exception:`,
          Logger.exception(e),
        ]);
        if (this.offlineAccess) {
          OAuthProvider.storage.setItem(SkipOfflineKey, 'yes');
          window.location.reload();
        } else this.extAuthenticated.resolve(false);
      });
    this.initialized = true;
  }

  abstract initializeClient(
    token?: string,
    refreshToken?: string,
    offlineAccess?: boolean,
    additionalScopes?: string[]
  ): Promise<boolean>;

  abstract createClient(config: AuthConfig, appUrl: string): T;

  abstract isLoggedIn(user: string): boolean;

  abstract get user(): string | undefined;

  abstract get userInfo(): UserInfoType;

  abstract get typeName(): string;

  static get storage() {
    return window.localStorage ? window.localStorage : window.sessionStorage;
  }

  override async getIdentityToken(): Promise<string | undefined> {
    return this.client?.idToken;
  }

  /** Returns the optional sub user ID read from the search params */
  getSubUserId(): string | undefined {
    const res = new URL(window.location.href).searchParams.get('u');
    return res ? res : undefined;
  }

  override async generateToken(scope: string) {
    const refreshed = await this.client?.updateToken(this.refreshTime);
    const token = this.client?.token || '';
    if (refreshed) this.tokenRefreshed.emit(token);

    if (this.refreshTimer === undefined)
      this.refreshTimer = setTimeout(() => {
        this.refreshTimer = undefined;
        this.generateToken(scope);
      }, this.refreshCheckInterval);

    if (OAuthProvider.storage.getItem('user')) this.storeTokens();

    const res: TokenGenerationResult = { token };
    const user = this.user;

    const subUser = this.getSubUserId();
    if (subUser) res.subUserId = subUser;

    if (user) {
      const sessStart = OAuthProvider.storage.getItem(user + '-sessionStart');
      if (sessStart) {
        const ts = parseInt(sessStart);
        if (Number.isInteger(ts)) res.sessionStart = ts;
      }
    }
    return res;
  }

  tryLogIn(user?: string | undefined): void {
    Logger.debug(['Trying', this.typeName, 'login for user:', user]);
    if (user) {
      if (this.isLoggedIn(user)) return;
      OAuthProvider.storage.setItem('user', user);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const win: any = window;
      if (win.webkit && win.webkit.messageHandlers && win.webkit.messageHandlers.clearAndReload) {
        win.webkit.messageHandlers.clearAndReload.postMessage({});
        return;
      } else if (win.Android) {
        win.Android.clearCookiesAndReload();
        return;
      }
    }
    const loginUser = user || OAuthProvider.storage.getItem('user') || undefined;
    if (loginUser) {
      this.resetUserTokens(loginUser);
    }

    const options: LoginOptions = {
      redirectUri: this.redirectUri,
      loginHint: loginUser,
      prompt: 'login',
      scope: this.offlineAccess ? 'offline_access' : undefined,
    };
    this.client?.login(options);
  }

  logOut(): void {
    const user = OAuthProvider.storage.getItem('user');
    Logger.debug([this.typeName, 'client logout for user:', user ?? undefined]);
    /* Inform others before re-directing */
    this.beforeLogOut.emit();
    if (user) {
      OAuthProvider.storage.removeItem('user');
      this.resetUserTokens(user);
    }
    this.client?.logout({ redirectUri: this.redirectUri });
  }

  authFailedReloadPeriod(): number {
    return 0;
  }

  protected resetUserTokens(user: string) {
    Logger.debug(['Clearing authorization tokens for', user]);
    OAuthProvider.storage.removeItem(user + '-token');
    OAuthProvider.storage.removeItem(user + '-refreshToken');
    OAuthProvider.storage.removeItem(user + '-sessionId');
    OAuthProvider.storage.removeItem(user + '-sessionStart');
  }

  protected storeTokens() {
    const user = this.user;

    if (this.client?.token && user) {
      OAuthProvider.storage.setItem('user', user);
      OAuthProvider.storage.setItem(user + '-token', this.client.token);
      if (this.client.refreshToken)
        OAuthProvider.storage.setItem(user + '-refreshToken', this.client.refreshToken);
      /* Set the session start timestamp if not set before or if the session ID changed */
      if (
        !OAuthProvider.storage.getItem(user + '-sessionStart') ||
        OAuthProvider.storage.getItem(user + '-sessionId') !== this.client.sessionId
      ) {
        const timestamp = now();
        Logger.debug(['Store session start for user:', user, 'as:', Logger.timestamp(timestamp)]);
        OAuthProvider.storage.setItem(user + '-sessionStart', timestamp.toString());
      }
      /* Store the session ID for later comparison */
      if (this.client.sessionId)
        OAuthProvider.storage.setItem(user + '-sessionId', this.client.sessionId);
    }
  }

  protected getScopes(): string[] {
    const scopes = [];
    scopes.push(...this.additionalScopes);
    if (this.offlineAccess) scopes.push('offline_access');
    return scopes;
  }

  protected getScopesAsString(): string | undefined {
    const scopes = this.getScopes().join(' ');
    return scopes.length === 0 ? undefined : scopes;
  }

  override async getAuthorizationHeader(scope: string): Promise<AuthorizationHeader> {
    /* Call base class*/
    const authHeader = await super.getAuthorizationHeader(scope);
    /* Check if a sub user ID needs to be set */
    const subUser = this.getSubUserId();
    return subUser ? { ...authHeader, 'Sqior-Sub-User-Id': subUser } : authHeader;
  }

  public initialized: boolean;
  readonly isAuthenticated: Promise<boolean>;
  protected extAuthenticated: ExternalPromise<boolean>;
  protected additionalScopes: string[];
  protected redirectUri: string | undefined;
  private refreshTimer: ReturnType<typeof setTimeout> | undefined;
  private readonly refreshTime: number;
  private readonly refreshCheckInterval: number;
  protected client: T | undefined;
  protected offlineAccess = false;
}

export const SkipOfflineKey = 'skipOffline';

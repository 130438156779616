import { ValueObject } from '@sqior/js/data';
import { Entity, EntityHeader, IdEntity } from '@sqior/js/entity';
import { OperationSpec, OperationType } from '@sqior/js/operation';
import { PatientInfo, SpecialtyVM } from '@sqior/viewmodels/patient';
import { DashboardStatePath, ListViewItem } from '@sqior/viewmodels/visual';
import { DischargeInfo } from './discharge-info-vm';
import { joinPath } from '@sqior/js/url';
import { OrderType } from './bed-allocation-vm';
import { SelectTimeRange } from '@sqior/viewmodels/input';
import {
  AnalyticsDashboardStatePath,
  WorkflowAnalyticsDashboardOpSubPaths,
} from '@sqior/viewmodels/analytics-dashboard';

export const BedAllocationWardListItemType = 'BedAllocationWardListItem';
export const WardSpecialtiesStatsInfoType = 'WardSpecialtiesStatsInfo';
export const WardSpecialtiesChartInfoType = 'WardSpecialtiesChartInfo';
export const WardSPIStatsInfoType = 'WardSPIStatsInfo';
export const WardSPIAndSpecialtiesInfoType = 'WardSPISpecialtiesChartInfo'; // Ward SPI and Specialties Chart

export const OccupancyDashboardType = 'OccupancyDashboard'; // Top Level Occupancy Dashboard with Ward switcher
export const WardOverviewDashboardType = 'WardOverviewDashboard'; // Detailed Ward Overview

export const BedAllocationDashboardType = 'BedAllocationDashboard';
export const BedAllocationInFlightPatientInfoType = 'BedAllocationInFlightPatientInfo';

export enum BedAllocationWardListItemBedState {
  Free = 'free',
  Blocked = 'blocked',
  Occupied = 'occupied',
}

export type BedBlockInfo = {
  strength?: string;
  reason?: string;
  start?: number;
  end?: number;
};
export type BedAllocationWardListItemBedInfo = {
  state: BedAllocationWardListItemBedState;
  sex?: string;
  inset?: string; // Represents the label within the bed-visualization
  // Filled in case state === Occupied
  patient?: PatientInfo;
  select?: OperationSpec<ValueObject>;
  dischargeInfo?: DischargeInfo;
  //Filled in case state === Blocked
  blockInfo?: BedBlockInfo;
};

export type BedAllocationWardListItemBed = {
  id: string;
  label: string;
  current: BedAllocationWardListItemBedInfo;
  other: BedAllocationWardListItemBedInfo[];
  later: BedAllocationWardListItemBedInfo;
  select?: OperationSpec<ValueObject>;
};

export type BedAllocationWardListItemEntity<IdType = IdEntity> = ListViewItem<IdType> & {
  label: string;
  beds: BedAllocationWardListItemBed[];
};

export type WardSpecialtiesStatsInfoItem = {
  specialty: string;
  quota: number;
  blockedSpecificly?: number;
  blockedUnspecificly?: number;
  availableBeds: number;
  availableBedsLater: number;
  patients: number;
  patientsWarning: boolean;
  arriving: number;
  leaving: number;
  freeBeds: number;
  freeBedsWarning: boolean;
  freeBedsLater: number;
  freeBedsLaterWarning: boolean;
  patientsLater: number;
  patientsLaterWarning: boolean;
};
export type WardSpecialtiesStatsInfoEntity<IdType = IdEntity> = ListViewItem<IdType> & {
  specialties: WardSpecialtiesStatsInfoItem[];
  totals: WardSpecialtiesStatsInfoItem & {
    freeBedsMale: number;
    freeBedsFemale: number;
    freeBedsDiverse: number;
    freeBedsUnisex: number;
    freeBedsLaterMale: number;
    freeBedsLaterFemale: number;
    freeBedsLaterDiverse: number;
    freeBedsLaterUnisex: number;
  };
};

export type WardSPIStatsInfoData = {
  spiValueLimit: number;
  spiMaxCountLimit: number;
  spiCountLimit: number;
};
export type WardSPIStatsInfoListViewEntity<IdType = IdEntity> = ListViewItem<IdType> &
  WardSPIStatsInfoData;

export type WardSPIAndSpecialtiesInfoEntityBase = EntityHeader & {
  specialties: WardSpecialtiesStatsInfoEntity;
  spiStats: WardSPIStatsInfoData;
};
export type WardSPIAndSpecialtiesInfoEntity<IdType = IdEntity> = ListViewItem<IdType> &
  WardSPIAndSpecialtiesInfoEntityBase;

export type BedAllocationInFlightPatientInfoEntity<IdType = IdEntity> = ListViewItem<IdType> & {
  name: string /* HTML */;
  birthday: string;
  patient: Entity;
  sex: string;
  requestSource: string;
  select: Entity;
  stepInfo?: string;
  challenged: boolean;
  fadeOut?: boolean;
  priority: boolean;
  order: OrderType;
  specialty?: SpecialtyVM;
  monitoring: boolean;
  isolation: boolean;
  nursingComplexity?: number;
};

// Occupancy Dashboard

export type WardOverviewDashboardVM = EntityHeader & {
  rooms: BedAllocationWardListItemEntity[];
  statistics: WardSpecialtiesStatsInfoEntity;
  spiStats: { spiValueLimit: number; spiMaxCountLimit: number; spiCountLimit: number };
  patientsInFlight: BedAllocationInFlightPatientInfoEntity[];
};

export type WardData = {
  id: string;
  label: string;
};
export type WardSelectionData = {
  wards: WardData[];
  selected: number;
};

export type OccupancyDashboardVM = EntityHeader & {
  mainDashboard?: Entity;
};

export enum OccupancyDashboardPaths {
  SelectWard = 'selectWard',
}
export function OccupancyDashboardSelect(wardId: string): OperationSpec<string> {
  return {
    type: OperationType.Add,
    path: joinPath(DashboardStatePath, OccupancyDashboardPaths.SelectWard),
    data: wardId,
  };
}

export function OccupancyAnalyticsDashboardTimeRange(
  select: string | { start?: number; end?: number }
) {
  return SelectTimeRange(
    joinPath(AnalyticsDashboardStatePath, WorkflowAnalyticsDashboardOpSubPaths.SelectTimeRange),
    select
  );
}

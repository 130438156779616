import { PluginContext } from '@sqior/js/plugin';
import { FactoryContext } from '@sqior/react/factory';
import { UIVisualPlugin } from '@sqior/react/uivisual';
import {
  PagePatientOverviewType,
  PatientInfoEntityType,
  StdActionButtonVMType,
  StdInfoVMType,
  TransportLocationSelectionType,
  TransportOrderInputType,
} from '@sqior/viewmodels/patient';
import PatientPage from './patient-page/patient-page';
import StdActionButton from './std-action-button/StdActionButton';
import TransportLocationSelectionControl from './transport-location-selection-control/TransportLocationSelectionControl';
import TransportOrderInputControl from './transport-order-input-control/transport-order-input-control';
import StdInfo from './std-info/StdInfo';
import PatientInfo from './patient-info/patient-info';

export function UIPatientPlugin(context: PluginContext & FactoryContext) {
  context.plugins.require(UIVisualPlugin, context);
  context.factory.add(TransportOrderInputType, TransportOrderInputControl);
  context.factory.add(TransportLocationSelectionType, TransportLocationSelectionControl);
  context.factory.add(PagePatientOverviewType, PatientPage);
  context.factory.add(StdActionButtonVMType, StdActionButton);
  context.factory.add(StdInfoVMType, StdInfo);
  context.factory.add(PatientInfoEntityType, PatientInfo);
}

/** Joins URL paths safely
 *
 */
export function joinUrlPath(...parts: string[]): string {
  if (isAbsoluteHttpUrl(parts[0])) {
    if (parts.length > 1) {
      const urlIn = new URL(parts[0]);
      const pathnamesJoined = posix_join(...[urlIn.pathname, ...parts.slice(1)]);
      urlIn.pathname = pathnamesJoined;
      return urlIn.href;
    } else return parts[0];
  } else return posix_join(...parts);
}

/**Returns true if URL starts with http:// or https://
 *
 */
export function isAbsoluteHttpUrl(urlIn: string) {
  let url;

  try {
    url = new URL(urlIn);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}

function posix_join(...parts: string[]): string {
  function trimSlashes(s: string) {
    if (s.charAt(0) === '/') s = s.substring(1);
    if (s.charAt(s.length - 1) === '/') s = s.substring(0, s.length - 1);
    return s;
  }

  const leadingSlash = parts[0].charAt(0) === '/';
  const trailingSlash = parts[parts.length - 1].charAt(parts[parts.length - 1].length - 1) === '/';

  const joinedPath = parts.map((part) => trimSlashes(part)).join('/');

  return (
    (leadingSlash && joinedPath.charAt(0) !== '/' ? '/' : '') +
    joinedPath +
    (trailingSlash && joinedPath.charAt(joinedPath.length - 1) !== '/' ? '/' : '')
  );
}

/** Append the 'sqiorOpenExternal=true' parameter to indeicate that the link shall be opened outside of native app
 *
 * @param url URL to enrich
 * @returns URL which contains 'sqiorOpenExternal=true'
 */
export function sqiorOpenExternal(url: string): string {
  const urlObj = new URL(url);
  urlObj.searchParams.set('sqiorOpenExternal', 'true');
  return urlObj.href;
}

export function extractIdFromUrl(urlOrPath: string) {
  const lastSlash = urlOrPath.lastIndexOf('/');
  if (lastSlash >= 0) {
    let id = urlOrPath.substring(lastSlash + 1);
    const dotPos = id.indexOf('.');
    id = id.substring(0, dotPos >= 0 ? dotPos : undefined);
    return id;
  }
  return undefined;
}

export const extractDomainWithPort = (url: string): string => {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.port ? `${parsedUrl.hostname}:${parsedUrl.port}` : parsedUrl.hostname;
  } catch (error) {
    console.error('Invalid URL provided:', error);
    return '';
  }
};

import { ReactComponent as InsuranceIcon } from './insurance.svg';
import styles from './insurance-info.module.css';

export interface InsuranceInfoProps {
  text?: string;
}

export function InsuranceInfo({ text }: InsuranceInfoProps) {
  if (!text) return null;
  return (
    <div className={styles['container']}>
      <InsuranceIcon className={styles['icon']} />
      <div className={styles['description']}>{text}</div>
    </div>
  );
}

export default InsuranceInfo;

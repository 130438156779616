import { CaveSeverity } from '@sqior/viewmodels/patient';
import CaveInfo from '../cave-info/cave-info';
import styles from './cave-infos-foldable.module.css';
import React, { useState } from 'react';

export interface CaveInfosFoldableProps {
  children?: React.ReactNode;
  severities: CaveSeverity[];
  defaultText?: string;
}

export function CaveInfosFoldable(props: CaveInfosFoldableProps) {
  const [expanded, setExpanded] = useState(false);

  const handleAccordionClick = () => {
    setExpanded(!expanded);
  };
  const childrenArray = React.Children.toArray(props.children);
  const shouldRenderExpanded = expanded || childrenArray.length <= 1;

  return (
    <div className={styles['container']} onClick={handleAccordionClick}>
      {shouldRenderExpanded && props.children}
      {!shouldRenderExpanded && (
        <CaveInfo
          severity={props.severities[0] || CaveSeverity.Info}
          description={`${props.defaultText ? props.defaultText : ''} ...`}
          showMultiple={props.severities.slice(1)}
        ></CaveInfo>
      )}
    </div>
  );
}

export default CaveInfosFoldable;

import { mixInSelectionOption, SelectionMenu, SelectionMenuContent } from '@sqior/viewmodels/input';
import { isEqual, ValueObject } from '@sqior/js/data';
import { ComponentFactory, FactoryProps } from '@sqior/react/factory';
import { OperationContext } from '@sqior/react/operation';
import { ClosablePage } from '@sqior/react/uibase';
import { useContext, useEffect, useRef, useState } from 'react';
import styles from './selection-page.module.css';
import { AddressInfoVM } from '@sqior/viewmodels/communication';
import { Entity } from '@sqior/js/entity';
import { useDynamicState } from '@sqior/react/state';
import { MessengerTabPath, MessengerTabStack } from '@sqior/viewmodels/app';

export type SelectionPageProps = FactoryProps<SelectionMenu>;

export function SelectionPage(props: SelectionPageProps) {
  const FactoryComponent = useContext(ComponentFactory);
  /* Normalize to array of selections */
  const selections: SelectionMenuContent[] =
    'selections' in props.data ? props.data.selections : [props.data];
  /* Isolate current selection */
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [results, setResults] = useState<ValueObject[]>([]);
  const currentSelection = selections[Math.min(selectedIndex, selections.length - 1)];
  const multiSelect = currentSelection.multiSelect;

  /* Automatically close if the messenger tab stack changes */
  const messengerTabStack = useDynamicState<MessengerTabStack>(MessengerTabPath, []);
  const initialTabStack = useRef(messengerTabStack);
  const close = props.onClose;
  useEffect(() => {
    /* Init value if it was not set before */
    if (!initialTabStack.current.length) {
      initialTabStack.current = messengerTabStack;
      return;
    }
    /* Compare with the previous value, automatically close if stack changed */
    if (close && !isEqual(messengerTabStack, initialTabStack.current)) close(false);
  }, [messengerTabStack, close]);

  /* Operation emitted */
  const dispatcher = useContext(OperationContext);
  const confirm = (data: ValueObject) => {
    if (props.data.operation) {
      dispatcher.start(mixInSelectionOption(props.data.operation, data));
    }
    if (props.onClose) props.onClose(true, data);
  };

  const onGroupChatSelection = (addresses: AddressInfoVM[], title?: string) => {
    const getAddresses = (addresses: AddressInfoVM[]) => {
      const result: Entity[] = [];
      for (const address of addresses) {
        if (address.chatAddress) result.push(address.chatAddress);
      }
      return result;
    };

    if (props.onClose)
      props.onClose(true, { addresses: getAddresses(addresses), title: title ?? '' });
  };

  return (
    <ClosablePage
      withBorder={currentSelection.header !== undefined}
      hideHeader={multiSelect}
      onClose={() => {
        if (props.onClose) props.onClose(false);
      }}
    >
      {currentSelection.header && <FactoryComponent data={currentSelection.header} />}
      <div className={styles[currentSelection.header ? 'container-for-header' : 'container']}>
        {currentSelection.selection.entityType !== 'AddressSelection' && (
          <div className={styles[currentSelection.header ? 'title-for-header' : 'title']}>
            {currentSelection.title}
          </div>
        )}
        <FactoryComponent
          data={currentSelection.selection}
          onSelection={(value: ValueObject | undefined) => {
            if (value === undefined) {
              props.onClose?.(false);
              return;
            }
            /* Check if there is a next selection */
            if (selections.length > selectedIndex + 1) {
              setResults(results.concat(value));
              setSelectedIndex(selectedIndex + 1);
            } else if (selections.length > 1) confirm({ selectedData: results.concat(value) });
            else confirm(value);
          }}
          multiSelect={multiSelect}
          onGroupChatSelection={onGroupChatSelection}
          title={currentSelection.title}
          onClose={() => {
            if (props.onClose) props.onClose(false);
          }}
        />
      </div>
    </ClosablePage>
  );
}

export default SelectionPage;

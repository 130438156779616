import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import useResizeObserver from 'use-resize-observer/polyfilled';
import { useEffect } from 'react';

type Order = 'asc' | 'desc';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T, isNumeric?: boolean) {
  const aValue = a[orderBy];
  const bValue = b[orderBy];

  // Convert strings to numbers for comparison if numeric
  const aNumber = isNumeric && typeof aValue === 'string' ? parseFloat(aValue) : aValue;
  const bNumber = isNumeric && typeof bValue === 'string' ? parseFloat(bValue) : bValue;

  if (bNumber < aNumber) {
    return -1;
  }
  if (bNumber > aNumber) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
  isNumeric: boolean
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, isNumeric)
    : (a, b) => -descendingComparator(a, b, orderBy, isNumeric);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export interface SortableTableProps {
  data: Array<{ [key: string]: string | number }>;
  columns: Array<{ id: string; label: string; numeric?: boolean }>;
}

function EnhancedTableHead(props: {
  columns: SortableTableProps['columns'];
  order: Order;
  orderBy: string;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  onHeaderResize?: (height: number) => void;
}) {
  const { ref } = useResizeObserver({
    onResize: ({ height }) => {
      if (props.onHeaderResize) {
        props.onHeaderResize(height ? height : 0);
      }
    },
  });

  const { order, orderBy, onRequestSort, columns } = props;
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead ref={ref}>
      <TableRow>
        {columns.map((column) => (
          <TableCell
            key={column.id}
            align={column.numeric ? 'right' : 'left'}
            sortDirection={orderBy === column.id ? order : false}
            sx={{
              borderBottom: '1px solid rgba(224, 224, 224, 0.6)',
              background: '#121830',
            }}
          >
            <TableSortLabel
              active={orderBy === column.id}
              direction={orderBy === column.id ? order : 'asc'}
              onClick={createSortHandler(column.id)}
            >
              {column.label}
              {orderBy === column.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export function SortableTable({ data, columns }: SortableTableProps) {
  const tableRef = React.useRef<HTMLDivElement>(null);

  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<string>(columns[0].id);
  const [headerHeight, setHeaderHeight] = React.useState(0);

  const [scrollbar, setScrollbar] = React.useState({
    height: 0,
    position: 0,
  });

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const sortableRows = React.useMemo(() => {
    const column = columns.find((col) => col.id === orderBy);
    const isNumeric = column?.numeric || false;
    return stableSort(data, getComparator(order, orderBy, isNumeric));
  }, [data, order, orderBy, columns]);

  useEffect(() => {
    const target = tableRef.current;
    if (!target) return;
    const { height, position } = calculateScrollbar(target, headerHeight);
    setScrollbar({
      height,
      position,
    });
  }, [data, columns, headerHeight]);

  const onScroll = (event: React.UIEvent<HTMLDivElement>) => {
    event.preventDefault();
    const target = event.currentTarget;
    const { height, position } = calculateScrollbar(target, headerHeight);
    setScrollbar({
      height,
      position,
    });
  };

  const onHeaderResize = (height: number) => {
    setHeaderHeight(height);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <TableContainer
        ref={tableRef}
        sx={{
          maxHeight: '100%',
          '&::-webkit-scrollbar': { display: 'none' }, // Chrome, Safari and Opera
          '-ms-overflow-style': 'none', // IE and Edge
          scrollbarWidth: 'none', // Firefox
        }}
        onScroll={onScroll}
      >
        <Table stickyHeader aria-labelledby="tableTitle" size="small">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            columns={columns}
            onHeaderResize={onHeaderResize}
          />
          <TableBody>
            {sortableRows.map((row, index) => (
              <TableRow
                hover
                tabIndex={-1}
                key={index}
                sx={{
                  cursor: 'pointer',
                }}
              >
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.numeric ? 'right' : 'left'}
                    sx={{
                      borderBottom:
                        index === sortableRows.length - 1
                          ? 'none'
                          : '1px solid rgba(224, 224, 224, 0.2)',
                    }}
                  >
                    {row[column.id]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!isNaN(scrollbar.position) && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              display: 'flex',
              height: headerHeight,
            }}
          ></div>
          <div
            style={{
              position: 'relative',
              width: 16,
              display: 'flex',
              flex: 1,
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                right: 0,
                top: scrollbar.position,
                width: 8,
                height: scrollbar.height,
                background: '#4B4F60',
                borderRadius: 4,
              }}
            />
          </div>
        </div>
      )}
    </Box>
  );
}

export default SortableTable;

const calculateScrollbar = (target: HTMLDivElement, headerHeight: number) => {
  const { scrollTop, clientHeight, scrollHeight } = target;

  // Calculate the available height (excluding header)
  const availableHeight = clientHeight - headerHeight;

  // Calculate the new scrollbar height
  const scrollbarHeight = (clientHeight / scrollHeight) * availableHeight;

  // Calculate the new scrollbar position
  const scrollbarPosition =
    (scrollTop / (scrollHeight - clientHeight)) * (availableHeight - scrollbarHeight);

  return {
    height: scrollbarHeight,
    position: scrollbarPosition,
  };
};

import { ServerConnection } from '@sqior/web/wsclient';
import { StateReceiver } from '@sqior/js/state-message';
import { useContext } from 'react';
import { ConfigContext, VersionInfo, VersionInfoContext } from '@sqior/react/utils';
import { StateContext, TimerProvider } from '@sqior/react/state';
import { OperationContext } from '@sqior/react/operation';
import { Dispatcher, OperationSpec, OperationState, TemporaryQueue } from '@sqior/js/operation';
import { OperationRest, OperationRestSwitch } from '@sqior/js/operation-rest';
import { StateOverlayMap } from '@sqior/js/state-operation';
import { AuthContext } from '@sqior/react/uiauth';
import { OperationSender } from '@sqior/js/operation-message';
import { ConnectionState, WebsocketCloseCode } from '@sqior/js/wsbase';
import {
  SetAppLifecycleListener,
  SetOperationPerformer,
  SetTokenSetter,
  SqiorNativeAppVersion,
  WillEnterForeground,
  isSqiorAppleClient,
  isSqiorMobileClient,
} from './ios-android-interop';
import { PushSystem, RegisterPushDevice } from '@sqior/js/push';
import { State } from '@sqior/js/state';
import {
  ServerConnectionContext,
  ServerConnectionObserver,
} from '../server-connection-context/server-connection-context';
import { LogSender, ReliableChannel } from '@sqior/js/message';
import { ComponentFactory, Factory } from '@sqior/react/factory';
import { LogRetainer, Logger } from '@sqior/js/log';
import { IConfigContext } from '@sqior/js/url';
import { AuthProvider } from '@sqior/js/authbase';
import { AppVersionInfo, AppVersionSystem, DemoModePath } from '@sqior/viewmodels/app';
import {
  LanguageTextResourceManager,
  LanguageTextResourceMap,
  MergingTextResourceState,
  TextResourceState,
} from '@sqior/js/language';
import { InterweaveExtContext, InterweaveExtFactoryContext } from '@sqior/react/uibase';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { de } from 'date-fns/locale';
import { StdTimer, TimerHolder, addSeconds } from '@sqior/js/data';
import { ConnectionMessageType } from '@sqior/js/session';
import { SqiorNativeAppVersionType } from './ios-android-interop-types';

function handlePushTokens(disp: Dispatcher, state: ConnectionState) {
  handleMobileToken(disp, state);
  handleWebPushToken(disp, state);
}

let mobilePushToken: [string, string] | undefined;
function handleMobileToken(disp: Dispatcher, state: ConnectionState) {
  function sendToServer(token: [string, string]) {
    disp.start(
      RegisterPushDevice(
        { system: token[1] === 'APN' ? PushSystem.APN : PushSystem.Firebase, token: token[0] },
        true
      )
    );
  }
  if (state === ConnectionState.CONNECTED) {
    const token = SetTokenSetter((token: [string, string]) => {
      Logger.debug(['Sending push token to server after definition:', token]);
      sendToServer((mobilePushToken = token));
    }) as [string, string];
    if (token?.length === 2) {
      Logger.debug(['Sending known push token to server:', token]);
      sendToServer((mobilePushToken = token));
    } else Logger.debug('Not sending mobile push token to server as it is not known (yet)');
  } // not connected
  else {
    mobilePushToken = undefined;
    SetTokenSetter(undefined);
  }
}

export type WebPushTokenSetterFunc = ((token: string) => void) | undefined;
let WebPushToken: string;
let WebPushTokenSetter: WebPushTokenSetterFunc;
function SetWebPushTokenSetter(setter: WebPushTokenSetterFunc) {
  WebPushTokenSetter = setter;
  return WebPushToken;
}
export function SetWebPushToken(webPushToken: string) {
  WebPushToken = webPushToken;
  if (WebPushTokenSetter) WebPushTokenSetter(webPushToken);
}
function handleWebPushToken(disp: Dispatcher, state: ConnectionState) {
  function sendToken(token: string) {
    disp.start(RegisterPushDevice({ system: PushSystem.Firebase, token: token }, false));
  }

  if (state === ConnectionState.CONNECTED) {
    const token = SetWebPushTokenSetter((token: string) => {
      sendToken(token);
    });
    if (token)
      setTimeout(() => {
        sendToken(token);
      }, 0);
  } // not connected
  else SetWebPushTokenSetter(undefined);
}

enum E_Results {
  OK = 'OK',
  ERR_WRONG_INPUT = 'ERR_WRONG_INPUT',
  ERR_FAILED = 'ERR_FAILED',
}
type PerformOperationCB = (operationStr: string, res: string) => void;

function performOperation(
  disp: Dispatcher,
  operationStr: string,
  callback: PerformOperationCB | undefined
) {
  console.log('performOperation(): ', JSON.stringify(disp), operationStr, callback);
  let operation: OperationSpec;
  try {
    operation = JSON.parse(operationStr);
  } catch (e) {
    console.log('performOperation() - cannot parse: ', operationStr);
    callback?.(operationStr, E_Results.ERR_WRONG_INPUT);
    return;
  }

  disp.start(operation, (state) => {
    console.log('-- state listener: ', state);
    if (state === OperationState.Completed) {
      console.log('-- calling callback - success: ', JSON.stringify(operation), true);
      callback?.(operationStr, E_Results.OK);
    } else if (state === OperationState.Failed) {
      console.log('-- calling callback - failed: ', JSON.stringify(operation), false);
      callback?.(operationStr, E_Results.ERR_FAILED);
    }
  });
}

export type CoreServicesConfig = {
  appState?: State;
  logRetainer?: LogRetainer; // Keeps log in memory or even WebStorage until they can be send out
  stateOverlays?: StateOverlayMap;
  factory: Factory;
  webSocketName: string; // Sub-path to access for the web-socket connection to the back-end
  clientTextResources: LanguageTextResourceMap;
} & InterweaveExtContext;

/* eslint-disable-next-line */
export interface CoreServicesProps {
  children: React.ReactNode;
  config: CoreServicesConfig;
  version: VersionInfo;
}

/** Creates the connection related core services */
class ConnectionServices {
  constructor(
    config: CoreServicesConfig,
    configContext: IConfigContext,
    authContext: AuthProvider,
    versionInfoClientContext: VersionInfo
  ) {
    /* Create the server connection */
    const sessionId = window.sessionStorage.getItem('sqior.ws.demo.sessionId');
    const uploadEndpoint = configContext.getWSEndpoint(config.webSocketName);
    this.connection = new ServerConnection(uploadEndpoint.href, versionInfoClientContext.version, {
      authContext: authContext,
      sessionId: sessionId === null ? undefined : sessionId,
    });
    this.connection.stateChanged.on((state) => {
      if (state === ConnectionState.CONNECTED)
        if (this.connection.sessionId !== undefined)
          window.sessionStorage.setItem('sqior.ws.demo.sessionId', this.connection.sessionId);
    });
    this.reliableChannel = new ReliableChannel(this.connection);
    /* Close connection and try to reconnect if the reliable channel discovers a network outage */
    this.reliableChannel.outage.on(() => {
      Logger.info('Network outage detected based on missing confirmation messages - reconnecting!');
      this.connection.disconnect();
    });

    /* Create the receiver for the server generated state */
    this.stateRecv = new StateReceiver(this.reliableChannel);
    /* Map app state in global state */
    if (config.appState) this.stateRecv.state.map('app', config.appState);
    /* Observe the server version */
    this.stateRecv.state.onSubTyped<string>('system/version/server', (serverVersion) => {
      if (
        serverVersion &&
        serverVersion.length > 0 &&
        serverVersion !== versionInfoClientContext.version
      ) {
        Logger.info([
          'Mismatch of server software version:',
          serverVersion,
          'with web client version:',
          versionInfoClientContext.version,
          '-> Reloading',
        ]);
        window.location.reload();
      }
    });
    /* Set the validation flag based on the demo mode */
    this.stateRecv.state.onSubTyped<boolean>(DemoModePath, (mode) => {
      Logger.validate = mode ?? false;
    });

    /* Build text resources for client: merge client text resources with server resources */
    const clientResourceManager = new LanguageTextResourceManager();
    clientResourceManager.addLanguageResources(config.clientTextResources);
    const clientBaseTextResources = new TextResourceState(clientResourceManager);
    clientBaseTextResources.setLanguage('de');
    const mergedTextResources = new MergingTextResourceState([
      clientBaseTextResources.state,
      this.stateRecv.state.subState('text-resource'),
    ]);
    this.stateRecv.state.map('merged-text-resource', mergedTextResources.state);

    /* Configuration of the operation dispatcher */
    this.dispatcher = new Dispatcher();
    /* Switch operation interface depending on suitability for REST (= binaries) */
    const opSwitch = new OperationRestSwitch();
    opSwitch.rest.attach(new OperationRest(configContext, authContext));
    opSwitch.other.attach(new OperationSender(this.reliableChannel));
    /* Handle the operation once all temporaries are resolved */
    this.dispatcher.register(new TemporaryQueue().attach(opSwitch));
    /* Create state overlays for this operation if applicable */
    if (config.stateOverlays) config.stateOverlays.configure(this.stateRecv.state, this.dispatcher);
    /* Observe the connection state - send the pre-existing device token if connected or wait for it to be set */
    this.reliableChannel.out.onOpen(() => {
      if (this.dispatcher) {
        handlePushTokens(this.dispatcher, ConnectionState.CONNECTED);
        // Bridge to native application performing operations
        console.log('calling SetOperationPerformer(value)');
        SetOperationPerformer(async (operationStr: string, callback: PerformOperationCB) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          performOperation(this.dispatcher!, operationStr, callback); // dispatcher is newly create some lines above
        });
        /* Set native app version if applicable */
        const versionInfo = SqiorNativeAppVersion as SqiorNativeAppVersionType;
        if (versionInfo && isSqiorMobileClient()) {
          this.dispatcher.start(
            AppVersionInfo({
              system: isSqiorAppleClient() ? AppVersionSystem.iOS : AppVersionSystem.Android,
              version: versionInfo.version,
              model: versionInfo.model,
            })
          );
        }
        /* Start a timer to check after some time, if a push token was received */
        if (isSqiorMobileClient())
          this.tokenCheckTimer.set(
            new StdTimer().schedule(() => {
              /* Check if a token was received */
              if (!mobilePushToken) Logger.warn('No push token received on mobile device');
            }, addSeconds(20))
          );
      }
    });
    this.reliableChannel.out.onClose(() => {
      if (this.dispatcher) {
        this.tokenCheckTimer.reset();
        handlePushTokens(this.dispatcher, ConnectionState.NOT_CONNECTED);
        console.log('calling SetOperationPerformer(undefined)');
        SetOperationPerformer(undefined);
      }
    });

    /* Create a log sender if log retainer is configured */
    if (config.logRetainer) {
      console.log('Connecting log retainer to log sender');
      this.logSender = new LogSender(this.reliableChannel, config.logRetainer);
    }

    /* Initialize connection observer */
    this.connectionObserver = new ServerConnectionObserver(this.connection, authContext);
    /* Listen to app lifecycle events and make sure that connection will be connected if app switches to foreground */
    SetAppLifecycleListener((state: string) => {
      if (state === WillEnterForeground) {
        /* Re-initialize the connection observer to start and give time for reconnecting */
        this.connectionObserver.reinitialize();
        /* If the connection seems to be established, send a ping immediately to validate if it is working
           as there seems to be an issue on iOS that the websocket connection close event is not always
           provided to the web view when the app is paused */
        this.connection.resetPing();
        /* Make sure the reliable channel is open */
        this.reliableChannel.open();
      }
    });

    /* Listen to a logout event to explicitly close connection */
    authContext.beforeLogOut.on(() => {
      /* Inform server about the explicit log-out if applicable */
      if (this.connection.out.isOpen)
        this.connection.out.send({ type: ConnectionMessageType.LogOut });
      /* Close connection, cannot be opened again */
      this.connection.close(WebsocketCloseCode.LogOut);
    });
  }

  connection: ServerConnection;
  reliableChannel: ReliableChannel;
  stateRecv: StateReceiver;
  dispatcher: Dispatcher;
  logSender?: LogSender;
  connectionObserver: ServerConnectionObserver;
  private tokenCheckTimer = new TimerHolder();
}

/* Use a global for now as putting this into useStateOnce() causes two connections to be generated.
   Debug logs have shown that only once Connection object constructor is called but for an unkown reason,
   the onOpen callback is called twice.
*/
let cs: ConnectionServices | undefined;

/** React container providing all core app services to its children */
export function CoreServices(props: CoreServicesProps) {
  // Get the ConfigContext to derive the corresponding endpoints
  const configContext = useContext(ConfigContext);
  // Get the IAuthContext to generate tokens if not undefined
  const authContext = useContext(AuthContext);
  /* Create the connection object if not created already */
  if (!cs)
    cs = new ConnectionServices(props.config, configContext, authContext.provider, props.version);

  return (
    <VersionInfoContext.Provider value={props.version}>
      <StateContext.Provider value={cs.stateRecv.state}>
        <OperationContext.Provider value={cs.dispatcher}>
          <ComponentFactory.Provider value={props.config.factory.get()}>
            <InterweaveExtFactoryContext.Provider value={props.config.interweaveFactory}>
              <ServerConnectionContext.Provider value={cs.connectionObserver}>
                <TimerProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
                    {props.children}
                  </LocalizationProvider>
                </TimerProvider>
              </ServerConnectionContext.Provider>
            </InterweaveExtFactoryContext.Provider>
          </ComponentFactory.Provider>
        </OperationContext.Provider>
      </StateContext.Provider>
    </VersionInfoContext.Provider>
  );
}

export default CoreServices;

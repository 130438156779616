import { FactoryProps } from '@sqior/react/factory';
import styles from './timestamp-input-control.module.css';
import { TimestampInputVM } from '@sqior/viewmodels/time';
import { TimePicker } from '@mui/x-date-pickers';
import { HighlightButton, useTextResources } from '@sqior/react/uibase';
import { useState } from 'react';
import { SelectionAreaProps } from '../selection-area';
import { useTimer } from '@sqior/react/state';

export type TimestampInputControlProps = FactoryProps<TimestampInputVM> & SelectionAreaProps;

export function TimestampInputControl(props: TimestampInputControlProps) {
  const { timer } = useTimer();
  const currentTime = timer.now;
  const [timestamp, setTimestamp] = useState(new Date(currentTime + props.data.offset));
  const [minTimestamp] = useState(new Date(currentTime + props.data.min));
  const [maxTimestamp] = useState(new Date(currentTime + props.data.max));
  const textDict = useTextResources();
  return (
    <div className={styles['container']}>
      <div className={styles['inner-container']}>
        <div className={styles['title']}>{props.data.label}</div>
        <div className={styles['picker']}>
          <TimePicker
            label={textDict.get('time_at')}
            value={timestamp}
            minTime={minTimestamp}
            maxTime={maxTimestamp}
            onChange={(value) => {
              if (value) setTimestamp(value);
            }}
            sx={{
              '& .MuiInputBase-root.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
                {
                  borderColor: 'yellow',
                },
            }}
          />
        </div>
      </div>
      <div className={styles['confirm']}>
        <HighlightButton
          onClick={() => {
            props.onSelection?.(
              props.data.textResult ?? true
                ? {
                    text: timestamp.toISOString(),
                  }
                : { entity: { entityType: 'Timestamp', timestamp: timestamp.getTime() } }
            );
          }}
        >
          {textDict.get('take_over')}
        </HighlightButton>
      </div>
    </div>
  );
}

export default TimestampInputControl;

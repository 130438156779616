import styles from './metric-card-header.module.css';
import { IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export interface MetricCardHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  subTitle?: string;
  legend?: React.ReactNode;
  hideSettings?: boolean;
}

export function MetricCardHeader({
  title,
  subTitle,
  hideSettings,
  legend,
  ...rest
}: MetricCardHeaderProps) {
  return (
    <div className={styles['title-container']} {...rest}>
      <div>
        <div className={styles['title']}>{title}</div>
        {subTitle && <div className={styles['sub-title']}>{subTitle}</div>}
      </div>

      {!hideSettings && (
        <div className={styles['legend-more-button']}>
          <div className={styles['legend-container']}>{legend}</div>

          <div className={styles['more-icon']}>
            <IconButton>
              <MoreVertIcon fontSize="small" />
            </IconButton>
          </div>
        </div>
      )}
    </div>
  );
}

export default MetricCardHeader;

import { now } from '@sqior/js/data';
import { Logger } from '@sqior/js/log';
import { KeycloakLogoutOptions } from 'keycloak-js';
import * as oauth from 'oauth4webapi';

type OidcClientParameters = {
  issuer: string;
  client_id: string;
  extraAuthUrlParams?: string;
  appUrl: string;
  algorithm?: 'oidc' | 'oauth2' | undefined;
  scope?: string;
};

export class OidcClient {
  constructor(params: OidcClientParameters) {
    this.issuer = new URL(params.issuer, window.location.href);
    this.appUrl = params.appUrl;
    this.client = { client_id: params.client_id, token_endpoint_auth_method: 'none' };
    this.authServer = undefined;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.authParams = [this.authServer!, this.client];
    this._userClaims = {} as User;
    this._sessionId = undefined;
    this._user = '';

    this.scope = 'openid profile email';
    this.algorithm = 'oidc';
  }

  async init(accessToken?: string, refreshToken?: string, additionalScopes?: string) {
    if (additionalScopes) {
      const uniqueScopes = new Set(additionalScopes.concat(' ', this.scope).split(' '));
      this.scope = [...uniqueScopes].join(' ');
      if (!this.scope.includes('openid')) this.algorithm = 'oauth2';
    }
    this.authServer = await oauth
      .discoveryRequest(this.issuer, { algorithm: this.algorithm })
      .then((response) => {
        return oauth.processDiscoveryResponse(this.issuer, response);
      })
      .catch((cause) => {
        Logger.error(['Could not get server metadata: ', Logger.exception(cause)]);
        throw new Error(
          'Could not get server metadata: for ' + this.issuer.href + ' because: ' + cause
        );
      });
    if (this.authServer === undefined)
      throw new Error('No Authentication Server set to handle request');
    if (!this.authServer.authorization_endpoint)
      throw new Error('Authorization endpoint not found!');
    this.authParams = [this.authServer, this.client];

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const tokensValid = await this.verifyTokens(accessToken);
    if (tokensValid) {
      this._userClaims.accessToken = accessToken;
      this._userClaims.refreshToken = refreshToken;
      return true;
    }

    return await this.performPKCE();
  }

  private confirmAuthServer() {
    if (!this.authServer) throw new Error('No authentication server instantiated!');
  }

  async login(options: LoginOptions) {
    const codeVerifier = oauth.generateRandomCodeVerifier();
    // this is the only place we calculate the code challenge
    const challenge = await oauth.calculatePKCECodeChallenge(codeVerifier);
    this.storage.setItem('code_verifier', codeVerifier);
    window.location.href = this.createAuthorizationUrl(challenge, options).href;
  }

  async refresh() {
    const user = this.storage.getItem('user');
    const refreshToken = this.storage.getItem(user + '-refreshToken');
    if (!(user && refreshToken)) throw new Error('User not authenticated');
    const response = await oauth.refreshTokenGrantRequest(...this.authParams, refreshToken);

    let challenges: oauth.WWWAuthenticateChallenge[] | undefined;
    if ((challenges = oauth.parseWwwAuthenticateChallenges(response))) {
      for (const challenge of challenges) {
        console.error('WWW-Authenticate Challenge', challenge);
      }
      throw new Error('WWW-Authenticate Challenge');
    }

    const result = await oauth.processRefreshTokenResponse(...this.authParams, response);
    if (oauth.isOAuth2Error(result)) {
      console.error('Error Response', result);
      throw new Error('Auth2 Error');
    }
    const claims = oauth.getValidatedIdTokenClaims(result);
    if (!claims) throw new Error('Could not get ID token claims from authentication server');

    this.storage.setItem(user + '-token', result.access_token);
    if (result.refresh_token) this.storage.setItem(user + '-refreshToken', result.refresh_token);
    this.userClaims.accessToken = result.access_token;
    this.userClaims.refreshToken = result.refresh_token;
    this.userClaims.timeSkew = this.timeSkew(claims.iat);
    this.userClaims.exp = claims.exp;
  }

  private isExpired(minValidity: number) {
    if (isNaN(minValidity)) throw new Error('Invalid minValidity');
    if (!this.userClaims.accessToken) throw new Error('Not authenticated');
    const expiresIn = this.userClaims.exp - Math.ceil(now() / 1000) + this.userClaims.timeSkew;
    return expiresIn - minValidity < 0;
  }

  async updateToken(minValidity: number) {
    const needsRefresh = this.isExpired(minValidity) || minValidity === -1;
    if (!needsRefresh) return false;
    await this.refresh();
    Logger.debug('Tokens were refreshed');

    return true;
  }

  async performPKCE() {
    this.confirmAuthServer();

    const verifier = this.storage.getItem('code_verifier');
    if (!verifier) return false;

    const url = this.currentUrl();

    const state = this.storage.getItem('state');
    if (!state) url.searchParams.delete('state');

    const params = oauth.validateAuthResponse(
      ...this.authParams,
      url,
      state ?? oauth.expectNoState
    );
    if (oauth.isOAuth2Error(params)) throw new Error('Error validating Server response');

    // already contains the tokens, rest is just validation
    const grant = await oauth.authorizationCodeGrantRequest(
      ...this.authParams,
      params,
      this.appUrl,
      verifier
    );

    this.storage.removeItem('code_verifier');
    this.storage.removeItem('state');

    const tokens = await oauth.processAuthorizationCodeOpenIDResponse(...this.authParams, grant);
    if (oauth.isOAuth2Error(tokens))
      throw new Error('Error obtaining openId connect token: ' + tokens.error);

    let challenges: oauth.WWWAuthenticateChallenge[] | undefined;
    if ((challenges = oauth.parseWwwAuthenticateChallenges(grant))) {
      for (const challenge of challenges) {
        console.error('WWW-Authenticate Challenge: ', challenge);
      }
      throw new Error('Error during www authenticate challenge');
    }

    const userInfo = await oauth.userInfoRequest(...this.authParams, tokens.access_token);

    const claims = oauth.getValidatedIdTokenClaims(tokens);
    const user = await oauth.processUserInfoResponse(...this.authParams, claims.sub, userInfo);
    if (oauth.isOAuth2Error(user)) throw new Error('Error retrieving user information');
    if (claims) {
      this._userClaims = {
        accessToken: tokens.access_token,
        idToken: tokens.id_token,
        refreshToken: tokens.refresh_token,
        exp: claims.exp,
        timeSkew: this.timeSkew(claims.iat),
        claims: user,
      };
      this._user = user.preferred_username ?? user.name ?? user.email?.split('@')[0] ?? user.sub;
      return true;
    }
    return false;
  }

  get sessionId(): string | undefined {
    return this._sessionId;
  }

  private set sessionId(id: string | undefined) {
    if (!id) throw new Error('Session id not set');
    this._sessionId = id;
  }

  private timeSkew(iat: number) {
    return Math.floor(now() / 1000) - iat;
  }

  async logout(options: KeycloakLogoutOptions) {
    const user = this.storage.getItem('user');
    const logoutUrl = this.authServer?.end_session_endpoint ?? this.appUrl;
    const idTokenHint = this.userClaims.idToken;
    if (logoutUrl) {
      const url = new URL(logoutUrl);
      if (user) url.searchParams.set('logout_hint', user);
      url.searchParams.set('prompt', 'login');
      if (idTokenHint) {
        url.searchParams.set('id_token_hint', idTokenHint);
        url.searchParams.set('post_logout_redirect_uri', options.redirectUri ?? '');
      }
      window.location.href = url.href;
    }
  }

  private createAuthorizationUrl(code_challenge: string, options: LoginOptions) {
    const code_challenge_method = 'S256';

    if (!this.authServer) throw new Error('No auth server was instantiated');

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const authorizationUrl = new URL(this.authServer.authorization_endpoint!);
    if (options.loginHint) authorizationUrl.searchParams.set('login_hint', options.loginHint);
    if (options.redirectUri) authorizationUrl.searchParams.set('redirect_uri', options.redirectUri);
    if (
      options.state === true ||
      this.authServer.code_challenge_methods_supported?.includes(code_challenge_method) !== true
    ) {
      const state = oauth.generateRandomState();
      this.storage.setItem('state', state);
      authorizationUrl.searchParams.set('state', state);
    }
    authorizationUrl.searchParams.set('scope', this.scope);
    authorizationUrl.searchParams.set('client_id', this.client.client_id);
    authorizationUrl.searchParams.set('response_type', 'code');
    authorizationUrl.searchParams.set('code_challenge', code_challenge);
    authorizationUrl.searchParams.set('code_challenge_method', code_challenge_method);

    return authorizationUrl;
  }

  get storage() {
    return window.localStorage ? window.localStorage : window.sessionStorage;
  }

  currentUrl() {
    return new URL(window.location.href);
  }

  get userClaims(): User {
    return this._userClaims;
  }

  private set userClaims(user: User) {
    this._userClaims = user;
  }

  get user(): string | undefined {
    return this._user;
  }

  get idToken(): string | undefined {
    return this.userClaims.idToken;
  }

  get token(): string | undefined {
    return this.userClaims.accessToken;
  }

  get refreshToken(): string | undefined {
    return this.userClaims.refreshToken;
  }

  private async verifyTokens(token?: string) {
    if (!token) return false;

    if (!this.authServer?.introspection_endpoint && token) {
      const userInfo = await oauth.userInfoRequest(...this.authParams, token);
      return userInfo.ok;
    }

    Logger.info('Testing preexisting tokens');

    const introspect = await oauth
      .introspectionRequest(...this.authParams, token)
      .then((re) => oauth.processIntrospectionResponse(...this.authParams, re));
    if (!oauth.isOAuth2Error(introspect) && !introspect.active) {
      return false;
    }
    Logger.info('Tokens ok');

    return true;
  }

  private issuer!: URL;
  private algorithm: 'oidc' | 'oauth2' | undefined;
  private authServer: oauth.AuthorizationServer | undefined;
  private readonly client: oauth.Client;
  private readonly appUrl: string;
  private authParams: RequestParameters;
  private _userClaims: User;
  private _user: string;
  private _sessionId: string | undefined;
  private scope: string;
}

type User = {
  accessToken?: string;
  idToken?: string | undefined;
  refreshToken?: string | undefined;
  exp: number;
  timeSkew: number;
  claims?: oauth.UserInfoResponse;
};

export type LoginOptions = {
  redirectUri?: string;
  loginHint?: string;
  prompt: 'login' | 'none' | undefined;
  scope?: string;
  state?: boolean;
};

type RequestParameters = [oauth.AuthorizationServer, oauth.Client];

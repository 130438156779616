import {
  ORWorkflowCountdownType,
  ORWorkflowIndicatorState,
  ORWorkflowIndicatorVM,
} from '@sqior/viewmodels/orworkflow';
import styles from './orworkflow-indicator-control.module.css';
import { ItemState } from '@sqior/viewmodels/visual';
import { CountdownTimer, TransportState } from '@sqior/react/uibase';
import { addMinutes } from '@sqior/js/data';

const getCountDownState = (stage: ORWorkflowCountdownType) => {
  switch (stage) {
    case ORWorkflowCountdownType.Anesthesia:
      return ItemState.Hot;
    case ORWorkflowCountdownType.Surgery:
      return ItemState.Highlighted;
    case ORWorkflowCountdownType.Emergency:
      return ItemState.Emergency;
  }
  return ItemState.Normal;
};

function transportState(stage: ORWorkflowIndicatorState) {
  return stage === ORWorkflowIndicatorState.TransportOrdered
    ? 'start'
    : stage === ORWorkflowIndicatorState.TransportRunning
    ? 'running'
    : stage === ORWorkflowIndicatorState.TransportCancelled
    ? 'stopped'
    : undefined;
}

export type ORWorkflowIndicatorControlProps = {
  indicator: ORWorkflowIndicatorVM;
  countdownBackgroundColor?: string;
};

export function ORWorkflowIndicatorControl({
  indicator,
  countdownBackgroundColor,
}: ORWorkflowIndicatorControlProps) {
  return (
    <div className={styles['container']}>
      {typeof indicator === 'object' && (
        <CountdownTimer
          countdownBackgroundColor={countdownBackgroundColor}
          timeout={indicator.countdown ?? 0}
          duration={indicator.countdownDuration ?? addMinutes(60)}
          state={getCountDownState(indicator.countdownType)}
        />
      )}
      {typeof indicator === 'string' && <TransportState state={transportState(indicator)} />}
    </div>
  );
}

export default ORWorkflowIndicatorControl;

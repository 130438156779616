import { FactoryProps } from '@sqior/react/factory';
import styles from './escalation-reason-input.module.css';
import {
  EscalationReasonInput as EscalationReasonInputData,
  SelectionEntities,
  EscalationReasonEntity,
  EscalationReason,
  SelectionMenuType,
} from '@sqior/viewmodels/input';
import { OptionsInput } from '../options-input-area/options-input-area';
import { SelectionAreaProps } from '../selection-area';
import { useContext } from 'react';
import { SelectionPageContext } from '../selection-page-context';

/* eslint-disable-next-line */
export type EscalationReasonInputProps = FactoryProps<EscalationReasonInputData> &
  SelectionAreaProps;

export function EscalationReasonInput(props: EscalationReasonInputProps) {
  const selContext = useContext(SelectionPageContext);

  return (
    <div className={styles['container']}>
      {props.data?.delays?.length > 0 && (
        <>
          <div className={styles['header']}>{props.data.delayHeader}</div>
          <OptionsInput
            options={props.data.delays}
            onClick={(v) => {
              props.onSelection?.({ entity: v });
            }}
          />
        </>
      )}
      {props.data?.others?.length > 0 && (
        <>
          <div className={styles['header']}>{props.data.otherHeader}</div>
          {/* for now, this is coded statically, if more reasons are going to be implemented, the concept needs to be expanded (SF) */}
          <OptionsInput
            options={props.data.others}
            onClick={(v, o) => {
              if (o?.visual) {
                const selPage = {
                  entityType: SelectionMenuType,
                  title: o?.visual,
                  selection: { entityType: SelectionEntities.Text, text: '' },
                };
                selContext(selPage, (ok, data) => {
                  if (ok && data) {
                    const text = data['text'];
                    if (typeof text === 'string') {
                      const denyReason: EscalationReasonEntity = {
                        entityType: 'EscalationReason',
                        reason: EscalationReason.Denied,
                        denyReason: text,
                      };
                      props.onSelection?.({ entity: denyReason });
                    }
                  }
                });
              }
            }}
          />
        </>
      )}
    </div>
  );
}

export default EscalationReasonInput;

import styles from './animated-header-layout.module.css';
import { memo, useContext, useEffect, useRef, useState } from 'react';
import { SvgIconExpandLess, SvgIconExpandMore } from '../svg-icon/svg-icon';
import { AnimatePresence, motion } from 'framer-motion';
import { WorkflowInfoVM } from '@sqior/viewmodels/patient';
import { ComponentFactory, FactoryElement } from '@sqior/react/factory';
import { v4 as uuidv4 } from 'uuid';

export interface AnimatedHeaderLayoutProps {
  items: WorkflowInfoVM[] | undefined;
}

export function AnimatedHeaderLayout({ items }: AnimatedHeaderLayoutProps) {
  const FactoryComponent = useContext(ComponentFactory);

  const longItemsRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const hiddenRef = useRef<HTMLDivElement>(null);

  const [expanded, setExpanded] = useState(false);
  const [isTextTruncated, setIsTextTruncated] = useState(false);

  const { itemsToDisplay, overflowItemsComp, longItemsToDisplayComp } = processItems(
    FactoryComponent,
    items,
    expanded
  );

  useEffect(() => {
    if (!hiddenRef.current) return;
    if (!containerRef.current) return;

    const hiddenHeight = hiddenRef.current.clientWidth;
    const containerHeight = containerRef.current.clientWidth;

    setIsTextTruncated(hiddenHeight > containerHeight);
  }, [items]);

  const toggleExpand = () => setExpanded((prev) => !prev);

  const variants = {
    hidden: { height: 0, opacity: 0 },
    visible: { height: 'auto', opacity: 1 },
  };

  return (
    <div className={styles['container']}>
      <div ref={containerRef} className={styles['row-items']} onClick={toggleExpand}>
        <div
          ref={contentRef}
          className={styles['items']}
          style={{
            whiteSpace: expanded ? undefined : 'nowrap',
          }}
        >
          <div className={styles['short-container']}>
            <div className={styles['short-info']}>{itemsToDisplay}</div>
            {(overflowItemsComp.length > 0 || isTextTruncated) && (
              <div className={styles['center-start']}>
                <div className={styles['icon-position']}>
                  {!expanded && <SvgIconExpandMore className={styles['icon']} />}
                  {expanded && <SvgIconExpandLess className={styles['icon']} />}
                </div>
              </div>
            )}
          </div>
          <div className={styles['column-items']} ref={longItemsRef}>
            {longItemsToDisplayComp}
          </div>
          <div className={styles['hidden']} ref={hiddenRef}>
            {longItemsToDisplayComp}
          </div>
        </div>
      </div>
      <AnimatePresence>
        {expanded && overflowItemsComp.length > 0 && (
          <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={variants}
            className={styles['column-items']}
          >
            <div className={styles['cont']}>{overflowItemsComp}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default memo(AnimatedHeaderLayout);

const processItems = (
  FactoryComponent: FactoryElement,
  items: WorkflowInfoVM[] | undefined,
  expanded: boolean
) => {
  if (!items)
    return { itemsToDisplay: [], overflowItemsComp: [], hasMinOneLongItemToDisplay: false };

  const showShortItems = items.filter((_) => _.alwaysVisibile && _.type === 'short');
  const showItemsComp = makeInfoComponents(FactoryComponent, showShortItems);

  const shortItemsHidden = items.filter((_) => !_.alwaysVisibile && _.type === 'short');

  const sortedShortItems = [...showShortItems, ...shortItemsHidden].sort((a, b) => {
    if (a.type === 'short' && b.type === 'long') {
      return -1;
    }
    if (a.type === 'long' && b.type === 'short') {
      return 1;
    }
    return 0;
  });

  const sortedShortItemsComp = makeInfoComponents(FactoryComponent, sortedShortItems);

  const overflowItems = items.filter((_) => !_.alwaysVisibile && _.type === 'long');
  const overflowItemsComp = makeInfoComponents(FactoryComponent, overflowItems);

  const longItemsToDisplay = items.filter((_) => _.alwaysVisibile && _.type === 'long');
  const longItemsToDisplayComp = makeInfoComponents(FactoryComponent, longItemsToDisplay);

  const hasMinOneLongItemToDisplay = !!items.find((_) => _.alwaysVisibile && _.type === 'long');

  const itemsToDisplay = expanded ? sortedShortItemsComp : showItemsComp;

  return { itemsToDisplay, longItemsToDisplayComp, overflowItemsComp, hasMinOneLongItemToDisplay };
};

const makeInfoComponents = (FactoryComponent: FactoryElement, data?: WorkflowInfoVM[]) => {
  if (!data) return [];

  const show = [...data].map((a) => {
    return <FactoryComponent data={a} key={uuidv4()} />;
  });
  return show;
};

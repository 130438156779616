import { factoryGetProp, FactoryProps } from '@sqior/react/factory';
import { StdActionButtonVM } from '@sqior/viewmodels/patient';
import { ActionButton } from '@sqior/react/uibase';

export type StdActionButtonProps = FactoryProps<StdActionButtonVM>;

export function StdActionButton(props: StdActionButtonProps) {
  const short = !!factoryGetProp<boolean | undefined>('short', props);
  const overflow = !!factoryGetProp<boolean | undefined>('overflow', props);
  return <ActionButton {...props.data} short={short} overflow={overflow} />;
}

export default StdActionButton;

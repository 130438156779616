import styles from './animated-header.module.css';
import { AnimatePresence, motion } from 'framer-motion';
import { useRef } from 'react';
import { usePrevious } from '@sqior/react/hooks';

export interface AnimatedHeaderProps {
  position: number;
  height: number;
  children: React.ReactNode;
  freeze: boolean;
  movedInPx: number;
}

enum HeaderVisibility {
  INITIAL = 'INITIAL',
  FULLY_VISIBLE = 'FULLY_VISIBLE',
  HIDDEN = 'HIDDEN',
}

const threshold = 10; // in px

export function AnimatedHeader({
  position,
  height,
  children,
  freeze,
  movedInPx,
}: AnimatedHeaderProps) {
  const visibility = useRef<HeaderVisibility>(HeaderVisibility.INITIAL);

  const prevScrollPosition = usePrevious(position);
  const smoothTransition = { duration: 0.3, ease: [0.25, 0.1, 0.25, 1] };

  const isInHeaderHeight = position <= height / 2 && position >= 0;

  const diff = prevScrollPosition ? Math.abs(position - prevScrollPosition) : 0;

  const getScrollDirection = () => {
    if (!prevScrollPosition) {
      if (position > 0) return 'DOWN';
      else return 'UP';
    }
    if (position === prevScrollPosition) return 'NO_MOVE';
    if (position > prevScrollPosition) return 'DOWN';
    return 'UP';
  };

  const scrollPosition = getScrollDirection();

  const getHeaderLastPosition = () => {
    switch (visibility.current) {
      case HeaderVisibility.FULLY_VISIBLE:
        return 0;
      case HeaderVisibility.HIDDEN:
        return -height;
      default:
        return 0;
    }
  };

  const calculatePosition = () => {
    if (freeze) {
      visibility.current = HeaderVisibility.FULLY_VISIBLE;
      return 0;
    }
    if (isInHeaderHeight && scrollPosition === 'DOWN') return 0;

    if (!freeze && diff < threshold && movedInPx < threshold) return getHeaderLastPosition();

    if (scrollPosition === 'UP') {
      visibility.current = HeaderVisibility.FULLY_VISIBLE;
      return 0;
    }

    if (scrollPosition === 'DOWN' && !isInHeaderHeight) {
      visibility.current = HeaderVisibility.HIDDEN;
      return -height;
    }

    if (scrollPosition === 'NO_MOVE') {
      return getHeaderLastPosition();
    }

    visibility.current = HeaderVisibility.FULLY_VISIBLE;
    return 0;
  };

  const updatedPosition = calculatePosition();

  return (
    <AnimatePresence initial={false}>
      <motion.div
        className={styles['container']}
        animate={{
          y: updatedPosition,
        }}
        transition={{
          y: smoothTransition,
        }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
}

export default AnimatedHeader;

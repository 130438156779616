import {
  AppFrame,
  AuthenticationSystem,
  createUIAppContext,
  isSqiorMobileClient,
  UIAppPlugin,
} from '@sqior/react/uiapp';
import {
  AppDrawer,
  AppToggle,
  firstPage,
  Messenger,
  UIMessengerPlugin,
  useMessengerTabState,
} from '@sqior/react/uimessenger';
import { UIWorkflowPlugin } from '@sqior/react/uiorworkflow';
import { UIUserPlugin } from '@sqior/react/uiuser';
import './App.css';

import { AskPushPermission } from '@sqior/react/notifications';
import { AppStyle, useAppStyle, useUIGlobalState } from '@sqior/react/state';
import { UIAnalyticsPlugin } from '@sqior/react/ui-analytics';
import { UITreatmentPathPlugin } from '@sqior/react/ui-treatment-path';
import { UIAdvancedPlugin } from '@sqior/react/uiadvanced';
import { UIBasePlugin } from '@sqior/react/uibase';
import { UIChatPlugin } from '@sqior/react/uichat';
import { UIChecklistPlugin } from '@sqior/react/uichecklist';
import { UIConversationPlugin } from '@sqior/react/uiconversation';
import { ParallelPhoneLayout } from '@sqior/react/uidemo';
import { UIPatientPlugin } from '@sqior/react/uipatient';
import { Dashboard } from '@sqior/react/uivisual';
import { VersionInfo } from '@sqior/react/utils';
import { AnalyticsDashboardStatePath } from '@sqior/viewmodels/analytics-dashboard';
import { AnimatePresence, motion } from 'framer-motion';
import { UIOccupancyPlugin } from 'libs/react/uioccupancy/src';
import { DashboardStatePath } from '@sqior/viewmodels/visual';
import { AppClientTextResources, MessengerTab } from '@sqior/viewmodels/app';
import { useEffect } from 'react';
import { useIsMobile, usePrevious } from '@sqior/react/hooks';
import { setDefaultOptions } from 'date-fns';
import { de } from 'date-fns/locale';

// Set the default locale globally
setDefaultOptions({ locale: de });

function getClass(style: AppStyle) {
  if (style === AppStyle.Messenger) return 'app-frame';
  if (style === AppStyle.Dashboard) return 'app-frame-dashboard';
  return 'app-frame-w-dashboard';
}

/* Currently not used, function to identify screen rotation of device
function isPortrait(){
  if (window.screen.orientation !== undefined) // Rest of the world
    return window.screen.orientation.type.includes('portrait');

  else if (window.orientation !== undefined) // Safari, iPhone (need to use deprecated API ... OMG)
    return window.orientation === 0 || window.orientation === 180;

  return false;
}
*/

/* Create the app context */
const context = createUIAppContext('assistant', AppClientTextResources);

/* Check if this is the main app or just an iFrame to confirm the log-in, check if this is not an iFrame of the demo phone layout */
const params = new URLSearchParams(window.location.search);
const testUser = params.get('testUser');
const confirmApp = window.parent !== window && !testUser;
if (!confirmApp) {
  /* Initialize plug-ins */
  context.plugins.require(UIBasePlugin, context);
  context.plugins.require(UIAdvancedPlugin, context);
  context.plugins.require(UIAppPlugin, context);
  context.plugins.require(UIChatPlugin, context);
  context.plugins.require(UIChecklistPlugin, context);
  context.plugins.require(UIConversationPlugin, context);
  context.plugins.require(UIMessengerPlugin, context);
  context.plugins.require(UIOccupancyPlugin, context);
  context.plugins.require(UIPatientPlugin, context);
  context.plugins.require(UITreatmentPathPlugin, context);
  context.plugins.require(UIAnalyticsPlugin, context);
  context.plugins.require(UIUserPlugin, context);
  context.plugins.require(UIWorkflowPlugin, context);
}

/* Returns the class to use for the specified style */

/** Main rendering function of the app */
function App() {
  const { appStyle } = useAppStyle();

  const versionInfo: VersionInfo = {
    version: process.env.REACT_APP_VERSION || 'Environment variable REACT_APP_VERSION missing',
    appnameInternal: process.env.REACT_APP_NAME || 'Environment variable REACT_APP_NAME missing',
    appname: 'sqior Assistant',
    mobileClient: isSqiorMobileClient(),
  };

  // TODO: get config from endpoint here
  // TODO: remove before flight
  const authProviderChoice = confirmApp
    ? AuthenticationSystem.KeycloakConfirm
    : window.location.href.includes('public')
    ? AuthenticationSystem.QRCode
    : AuthenticationSystem.OAuth;

  return (
    <AppFrame
      config={{
        core: context,
        auth: authProviderChoice,
      }}
      version={versionInfo}
    >
      {appStyle !== AppStyle.ParallelPhoneDemo && !confirmApp && (
        <div className={getClass(appStyle)}>
          {appStyle !== AppStyle.Dashboard && <AskPushPermission className="top-status" />}

          {(appStyle === AppStyle.Combined ||
            appStyle === AppStyle.Dashboard ||
            appStyle === AppStyle.Analytics) && (
            <Dashboard
              className="app-dashboard"
              path={
                appStyle === AppStyle.Analytics ? AnalyticsDashboardStatePath : DashboardStatePath
              }
            />
          )}
          {appStyle !== AppStyle.Dashboard && (
            <div className="app-messenger">
              <div className="app-messenger-menu">
                <AppDrawer />
              </div>
              <MessengerWrapper appStyle={appStyle} />
              <div className="app-messenger-toggle">
                <AppToggle />
              </div>
            </div>
          )}
        </div>
      )}
      {appStyle === AppStyle.ParallelPhoneDemo && <ParallelPhoneLayout />}
    </AppFrame>
  );
}

export default App;

interface MessengerWrapperProps {
  appStyle: AppStyle;
}

const MessengerWrapper = ({ appStyle }: MessengerWrapperProps) => {
  const { UIGlobalState, setUIGlobalState } = useUIGlobalState();
  const tabMessenger = useMessengerTabState();
  const activeMessengerTab = firstPage(tabMessenger);
  const prevTabMessenger = usePrevious(tabMessenger);

  const isMobile = useIsMobile();

  /* Open messenger if e.g. patient is selected in dashboard */
  useEffect(() => {
    let passThrough = false;
    const lastItem = tabMessenger[tabMessenger.length - 1];
    const prevLastItem = prevTabMessenger ? prevTabMessenger[prevTabMessenger.length - 1] : null;

    if (activeMessengerTab === MessengerTab.Analytics) return;
    if (lastItem === prevLastItem) return;

    const hasData = lastItem as unknown as { lastChanged: number };
    if (hasData?.lastChanged) {
      passThrough = true;
    }

    if ((lastItem === 'menu' || prevLastItem === 'menu') && !passThrough) return;

    setUIGlobalState((prev) => ({ ...prev, messengerOpen: true }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabMessenger]);

  const ontoggleMessenger = () => {
    setUIGlobalState((prevState) => ({
      ...prevState,
      messengerOpen: !prevState.messengerOpen,
    }));
  };

  return (
    <>
      <AnimatePresence initial={false}>
        {UIGlobalState.messengerOpen && (
          <motion.div
            className="messenger-wrapper"
            initial={{
              maxWidth: 0,
              opacity: 0,
            }}
            animate={{
              maxWidth: 400,
              opacity: 1,
            }}
            exit={{
              width: 0,
              opacity: 0,
            }}
            style={{
              overflow: 'hidden',
              zIndex: 202,
              position: 'relative',
            }}
          >
            <Messenger fullDashboard={appStyle !== AppStyle.Combined} />
            {!isMobile && <div className={'extend-toggle'} onClick={ontoggleMessenger} />}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

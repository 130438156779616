import { EntityHeader } from '@sqior/js/entity';

export const QRCodeContentType = 'QRCodeContentType';

export enum QRCodeType {
  //LinearCode128 = 'Linear-Code-128',
  Code2of5Interleaved = 'Code-2of5-Interleaved',
}
export type QRCodeContent = EntityHeader & {
  code: string;
  type: QRCodeType;
};

import { factoryGetProp, FactoryProps } from '@sqior/react/factory';
import { useDynamicState } from '@sqior/react/state';
import { ButtonClose, ClosablePage, HighlightButton, useTextResources } from '@sqior/react/uibase';
import {
  DeviceListStatePath,
  EmptyUserDeviceList,
  PairingFeaturePublicServerUrlPath,
  PairingPublicServerCustomerPath,
  UserDeviceListVM,
  UserPairedPhoneStatePath,
} from '@sqior/viewmodels/app';
import { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import DeviceList from '../device-list/device-list';
import styles from './paired-device-page.module.css';
import { Interweave } from 'interweave';

export default function PairedDevicePage(props: FactoryProps) {
  const token = useDynamicState<string>(UserPairedPhoneStatePath, '');
  const deviceList = useDynamicState<UserDeviceListVM>(DeviceListStatePath, EmptyUserDeviceList);

  const [numDevices, setNumDevices] = useState<number>(0);
  const [addingNew, setAddingNew] = useState(false);

  useEffect(() => {
    if (deviceList.devices.length > numDevices) setAddingNew(false);
    setNumDevices(deviceList.devices.length);
  }, [deviceList, numDevices]);

  const textResource = useTextResources();

  const toggleAddingNew = () => {
    setAddingNew((prev) => !prev);
  };

  const onClosePage = () => {
    setAddingNew(false);
  };

  const publicServer = useDynamicState<string>(PairingFeaturePublicServerUrlPath, '');
  const publicServerCustomer = useDynamicState<string>(PairingPublicServerCustomerPath, '');

  return (
    <ClosablePage
      onClose={() => props.onClose?.(true, undefined)}
      className={factoryGetProp('className', props)}
    >
      <div className={styles['container']}>
        <DeviceList
          data={{ entityType: 'sd', devices: deviceList.devices }}
          textResources={textResource}
        />
        <div>
          <HighlightButton disabled={addingNew} onClick={toggleAddingNew}>
            {textResource.get('add_paired_device')}
          </HighlightButton>
        </div>
        {addingNew && (
          <div className={styles['overlay']}>
            <ButtonClose additionalClassName={styles['btn-close']} onClick={onClosePage} />

            <div className={styles['flex-1']}>
              <div className={styles['content']}>
                <div className={styles['header']}>
                  <div className={styles['header-title']}>{textResource.get('scan_qr_code')}</div>
                  <div className={styles['header-description']}>
                    <Interweave
                      content={replacePlaceholders(
                        textResource.get('qr_code_scan_description_pairing_1'),
                        {
                          publicServer,
                        }
                      )}
                    />
                  </div>
                </div>

                <div className={styles['center']}>
                  <div className={styles['qrCode']}>
                    <QRCode value={token} />
                  </div>
                </div>

                <div className={styles['problem-container']}>
                  <div className={styles['header-description']}>
                    <Interweave
                      content={replacePlaceholders(
                        textResource.get('qr_code_scan_description_pairing_2'),
                        {
                          publicServer,
                          publicServerCustomer,
                        }
                      )}
                    />
                  </div>

                  {/*<div>{textResource.get('qr_code_problem')}</div>*/}
                  {/*<div className={styles['primary-text']} onClick={onContactUs}>*/}
                  {/*  {textResource.get('contact_us')}*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </ClosablePage>
  );
}

type ReplacementMap = { [key: string]: string };

const replacePlaceholders = (text: string, replacements: ReplacementMap): string => {
  return text.replace(/\{([^}]+)}/g, (_, key) => replacements[key] || `{${key}}`);
};

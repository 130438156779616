import { Entity } from '@sqior/js/entity';
import { OccupancyWorkflowOverview } from './occupancy-overview';

/* Parameterless entity representing the parameters of a patient discharge request to be put in */

export const PatientDischargeRequestParametersInputType = 'PatientDischargeRequestParametersInput';
export type PatientDischargeRequestParametersInput = Entity;

/* Bed allocation workflow overview */

export const PatientDischargeOverviewType = 'PatientDischargeOverview';

export type PatientDischargeOverviewEntity = OccupancyWorkflowOverview;

import { Value, addSeconds } from '@sqior/js/data';
import { AddOperation, Operation, OperationState } from '@sqior/js/operation';
import { StateOverlay } from '@sqior/js/state';

/** State overlay helper inspecting the value until it matches the expectations */
export function ValueInspectionStateOverlay<OpDataType, StateDataType extends Value>(
  op: Operation,
  defValue: StateDataType,
  check: (state: StateDataType, op: OpDataType) => boolean,
  modify: (state: StateDataType, op: OpDataType) => StateDataType
) {
  if (!(op instanceof AddOperation)) return undefined;
  const opData = op.data as OpDataType;

  /* Create overlay setting location */
  const overlay = StateOverlay.create<StateDataType>((value) => {
    /* Clear the overlay if the specified value is found */
    if (check(value, opData)) {
      setTimeout(() => {
        overlay.clear();
      });
      return value;
    }
    return modify(value, opData);
  }, defValue);

  /* Clear overlay if operation failed or if it completed and the value has not reached the desired state after some seconds */
  op.stateChange.on((state) => {
    if (state === OperationState.Failed) overlay.clear();
    else if (state === OperationState.Completed)
      setTimeout(() => {
        overlay.clear();
      }, addSeconds(5));
  });

  return overlay;
}

import styles from './card-layout.module.css';

export interface CardLayoutProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  noPadding?: boolean;
  noStyle?: boolean;
  noGap?: boolean;
}

export function CardLayout({ children, noStyle, noPadding, noGap, ...rest }: CardLayoutProps) {
  return (
    <div
      className={styles['container']}
      style={{
        padding: noPadding ? '0' : undefined,
        border: noStyle ? 'none' : undefined,
        backgroundColor: noStyle ? 'transparent' : undefined,
        gap: noGap ? '0' : undefined,
      }}
      {...rest}
    >
      {children}
    </div>
  );
}

export default CardLayout;

import { ReactComponent as DescriptionIcon } from './description.svg';
import styles from './diagnosis-info.module.css';

export interface DiagnosisInfoProps {
  diagnosis?: string;
}

export function DiagnosisInfo({ diagnosis }: DiagnosisInfoProps) {
  if (!diagnosis) return null;
  return (
    <div className={styles['container']}>
      <DescriptionIcon className={styles['icon']} />
      <div className={styles['description']}>{diagnosis}</div>
    </div>
  );
}

export default DiagnosisInfo;
